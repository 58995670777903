<template>
    <span>{{ formattedDate }}</span>
</template>

<script>
export default {
    props: {
        jsonDate: {
            type: String,
            default() {
                return null
            },
        },
    },
    computed: {
        formattedDate() {
            if (!this.jsonDate) return
            let now = new Date()
            let then = new Date(this.jsonDate)
            const _MS_PER_DAY = 1000 * 60 * 60 * 24
            if ((now - then) / _MS_PER_DAY < 7) return then.toLocaleString('default', { weekday: 'long' })
            else if (now.getYear() == then.getYear()) return then.toLocaleString('default', { month: 'long', day: 'numeric' })
            return then.toLocaleString('default', { year: 'numeric', month: 'long', day: 'numeric' })
        },
    },
    methods: {},
}
</script>

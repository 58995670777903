<template>
    <div class="showsContainer">
        <div v-for="podcast in availablePodcasts" :key="podcast.id" class="showContainer" @click="$router.push({ name: 'podcast', params: { podcastId: podcast.id } })">
            <img :src="$directoryApi.podcasts.artworkRoute(podcast.id)" class="artwork" />
            <div class="subtitle">{{ podcast.title }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        buildKey: String,
    },
    data() {
        return {
            availablePodcasts: [],
        }
    },
    mounted() {
        this.getAvailablePodcasts()
    },
    methods: {
        async getAvailablePodcasts() {
            try {
                var response = await this.$directoryApi.podcasts.all(this.buildKey)
                this.availablePodcasts = response.data
            } catch (err) {
                console.log(err)
            }
        },
    },
}
</script>

<style scoped>
.showsContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    cursor: pointer;
}

.showContainer {
    width: 10rem;
}

.artwork {
    padding-top: 0.5rem;
    width: 100%;
}

.subtitle {
    overflow-y: hidden;
}
</style>

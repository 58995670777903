<template>
    <router-view />
</template>

<style>
#app {
    font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    background-color: black;
    color: white;
    text-align: center;
}

body {
    background: radial-gradient(circle at 90% 10%, #23547766 0, #09061b66 80%), radial-gradient(circle at 90% 90%, #95259166 0, #09061b66 80%);
    margin: 0px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

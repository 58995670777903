import { createApp } from 'vue'
import App from './App.vue'
import { createAuth } from '@/plugins/oidc'
import router from './router'
import directoryApi from '../../Shared/directoryApi'
import '@fontsource/montserrat'
import '@mdi/font/css/materialdesignicons.css'
import primeVue from 'primevue/config'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'

import Button from 'primevue/button'
import Ripple from 'primevue/ripple'

const app = createApp(App)

fetch(process.env.BASE_URL + 'config.json')
    .then((response) => response.json())
    .then((config) => {
        const auth = createAuth(config.oidc)
        auth.useRouter(router)
        app.use(auth)
        
        app.use(router)

        directoryApi.configure(config.apiServer)
        directoryApi.useAuth(auth)
        app.use(directoryApi)

        app.use(primeVue, { ripple: true })

        app.component('pv-button', Button)
        app.directive('ripple', Ripple)

        app.mount('#app')
    })

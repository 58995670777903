<template>
    <div class="layout">
        <div>
            <img :src="$directoryApi.podcasts.artworkRoute(podcastId)" class="artworkMed" />
        </div>
        <div>
            <div class="title">{{ podcast.title }}</div>
            <div class="description">{{ podcast.description }}</div>
        </div>
        <div>
            <episodeTable :episodes="podcast.episodes" @click:episode="emitPlaybackRequest" />
        </div>
    </div>
</template>

<script>
import emitter from '@/services/emitter'
import episodeTable from '@/components/episodeTable'

export default {
    components: {
        episodeTable,
    },
    props: {
        podcastId: String,
    },
    data() {
        return {
            podcast: {},
        }
    },
    mounted() {
        this.getPodcastWithEpisodes(this.podcastId)
    },
    methods: {
        emitPlaybackRequest(episode) {
            emitter.emit('requestEpisodePlayback', episode.id)
        },
        async getPodcastWithEpisodes(podcastId) {
            try {
                var response = await this.$directoryApi.podcasts.one(podcastId)
                response.data.episodes.sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))
                this.podcast = response.data
            } catch (err) {
                console.log(err)
            }
        },
    },
}
</script>

<style scoped>
.artworkMed {
    width: 15vw;
}

.layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem;
    gap: 2rem;
}

.layout > div:nth-child(1) {
    flex: 0;
}

.layout > div:nth-child(2) {
    flex: 1;
    text-align: left;
}

.layout > div:nth-child(3) {
    flex-basis: 100%;
}

.title {
    font-size: 2rem;
    font-weight: bold;
}

.description {
    font-size: 1rem;
}
</style>

<template>
    <div class="parent">
        <div class="sidebar">
            <ul class="list-none p-2 m-0">
                <li class="mb-2">
                    <a v-ripple class="flex align-items-center cursor-pointer p-3 lg:justify-content-center border-round text-pink-500 hover:text-cyan-500 transition-duration-150 transition-colors p-ripple" @click="$router.push({ name: 'browse' })">
                        <span class="mdi mdi-format-list-bulleted-square mdi-24px" :class="{ 'text-cyan-500': $route.name === 'browse' }" />
                    </a>
                </li>
                <!-- <li class="mb-2">
                    <a v-ripple class="flex align-items-center cursor-pointer p-3 lg:justify-content-center border-round text-pink-500 hover:text-cyan-500 transition-duration-150 transition-colors p-ripple" @click="$router.push({ name: 'followed' })">
                        <span class="mdi mdi-star mdi-24px" :class="{ 'text-cyan-500': $route.name === 'followed' }" />
                    </a>
                </li>
                <li class="mb-2">
                    <a v-ripple class="flex align-items-center cursor-pointer p-3 lg:justify-content-center border-round text-pink-500 hover:text-cyan-500 transition-duration-150 transition-colors p-ripple" @click="activeTab1 = 2">
                        <span class="mdi mdi-check-circle-outline mdi-24px" :class="{ 'text-cyan-500': $route.name === 'latest' }" />
                    </a>
                </li> -->
            </ul>
            <div class="footer">
                <!-- <pv-button @click="logout" class="p-button-raised p-button-help">Logout</pv-button> -->
            </div>
        </div>
        <div class="browser">
            <router-view />
        </div>
        <div class="player">
            <player />
        </div>
    </div>
</template>

<script>
import player from '@/views/Player'

export default {
    components: {
        player,
    },
    data() {
        return {}
    },
    methods: {
        // logout() {
        //     this.$auth.signOut()
        // },
    },
}
</script>

<style>
.parent {
    display: grid;
    grid-template-columns: 100pt auto 300pt;
    height: calc(100vh - 150px);
    width: calc(100vw - 150px);
}

.sidebar {
    background: radial-gradient(circle at 50% 80%, #333e4b70 0, #30323d 100%);
    position: relative;
}

.browser {
    background: radial-gradient(circle at 40% 60%, #25225150 0, #3c203650 100%);
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.browser::-webkit-scrollbar {
    display: none;
}

.player {
    background: radial-gradient(circle at center, #333e4b50 0, #30323d50 100%);
    position: relative;
}

.footer {
    bottom: 0;
    width: 100%;
    position: absolute;
    text-align: center;
}
</style>

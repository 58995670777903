export default class {
    constructor(axiosInstance) {
        this.axiosInstance = axiosInstance
    }

    search(terms) {
        return this.axiosInstance.get(`itunes/search?term=${terms}`)
    }

    artwork100Route(collectionId) {
        return this.axiosInstance.getUri({ url: `itunes/${collectionId}/artwork100` })
    }
}

export default class {
    constructor(apiService) {
        this.axiosInstance = apiService
    }

    all() {
        return this.axiosInstance.get(`builds`)
    }

    one(buildKey) {
        return this.axiosInstance.get(`builds/${buildKey}`)
    }

    addOne(buildKey) {
        return this.axiosInstance.post(`builds/${buildKey}`)
    }

    deleteOne(buildKey) {
        return this.axiosInstance.delete(`builds/${buildKey}`)
    }
}

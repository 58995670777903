export default class {
    constructor(apiService) {
        this.axiosInstance = apiService
    }

    audioRoute(episodeId) {
        return this.axiosInstance.getUri({ url: `episodes/${episodeId}/audio` })
    }

    one(episodeId) {
        return this.axiosInstance.get(`episodes/${episodeId}`)
    }
}

<template>
    <div class="episodesContainer">
        <div v-for="episode in episodes" :key="episode.id" class="episodeRow" @click="$emit('click:episode', episode)">
            <div class="publishDate"><publish-date :jsonDate="episode.publishDate" /></div>
            <div>{{ episode.title }}</div>
            <div class="duration">{{ episode.duration }}</div>
        </div>
    </div>
</template>

<script>
import publishDate from './publishDate'

export default {
    components: {
        publishDate,
    },
    props: {
        episodes: {
            type: Array,
            default() {
                return []
            },
        },
    },
    computed: {},
    methods: {},
}
</script>

<style scoped>
.episodesContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.episodeRow {
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    border-radius: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    background-color: #00000011;
}

.episodeRow:hover {
    background-color: #00000044;
}

.publishDate {
    font-size: 0.75rem;
}

.duration {
    font-size: 0.75rem;
}
</style>

import axios from 'axios'
import buildsRoutes from './routes/buildsRoutes'
import episodesRoutes from './routes/episodesRoutes'
import iTunesRoutes from './routes/iTunesRoutes'
import podcastsRoutes from './routes/podcastsRoutes'

const axiosInstance = axios.create()
const builds = new buildsRoutes(axiosInstance)
const episodes = new episodesRoutes(axiosInstance)
const iTunes = new iTunesRoutes(axiosInstance)
const podcasts = new podcastsRoutes(axiosInstance)

function configure(apiServer) {
    axiosInstance.defaults.baseURL = (apiServer.ssl ? 'https' : 'http') + '://' + apiServer.host + ((apiServer.ssl && apiServer.port == '443') || (!apiServer.ssl && apiServer.port == '80') || !apiServer.port ? '' : ':' + apiServer.port) + '/1/'
}

function install(app) {
    app.config.globalProperties.$directoryApi = this
}

function useAuth(auth) {
    axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
        return config
    })
}

const apiService = {
    configure,
    install,
    useAuth,
    builds,
    episodes,
    iTunes,
    podcasts,
}

export default apiService

import { createRouter, createWebHistory } from 'vue-router'

//import Followed from '@/views/Followed'
import Browse from '@/views/Browse'
import Build from '@/views/Build'
import Podcast from '@/views/Podcast'
import Redirect from '@/views/Redirect'

const routes = [
    { path: '/', name: 'redirect', component: Redirect, meta: { requiresAuth: true } },
    {
        path: '/:buildKey',
        name: 'build',
        component: Build,
        meta: { requiresAuth: true },
        children: [
            // { path: '/followed', name: 'followed', component: Followed, meta: { requiresAuth: true } },
            { path: '', name: 'browse', component: Browse, props: true, meta: { requiresAuth: true } },
            { path: ':podcastId', name: 'podcast', component: Podcast, props: true, meta: { requiresAuth: true } },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router

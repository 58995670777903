export default class {
    constructor(axiosInstance) {
        this.axiosInstance = axiosInstance
    }

    all(buildKey) {
        return this.axiosInstance.get(`${buildKey}/podcasts`)
    }

    followed() {
        return this.axiosInstance.get('podcasts')
    }

    one(id) {
        return this.axiosInstance.get(`podcasts/${id}`)
    }

    addToBuild(buildKey, collectionId) {
        return this.axiosInstance.post(`${buildKey}/podcasts`, { collectionId })
    }

    removeFromBuild(buildKey, collectionId) {
        return this.axiosInstance.delete(`${buildKey}/podcasts/${collectionId}`)
    }

    artworkRoute(podcastId) {
        return this.axiosInstance.getUri({ url: `podcasts/${podcastId}/artwork` })
    }
}

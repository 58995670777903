<template>
    <div class="top">
        <div class="episodeTitle">{{ episode?.title }}</div>
        <div class="podcastTitle">{{ episode?.podcast.title }}</div>
        <div class="podcastPublishDate"><publish-date :jsonDate="episode?.publishDate" /></div>
    </div>
    <div class="bottom" v-if="episode">
        <div class="artwork" :style="{ backgroundImage: episode ? `url(${$directoryApi.podcasts.artworkRoute(episode.podcast.id)})` : undefined }" />
        <audio class="controls" controls preload="none" autoplay :src="episode ? $directoryApi.episodes.audioRoute(episode.id) : undefined"></audio>
    </div>
</template>

<script>
import emitter from '@/services/emitter'
import publishDate from '@/components/publishDate'

export default {
    components: {
        publishDate,
    },
    created() {
        emitter.on('requestEpisodePlayback', async (episodeId) => {
            await this.fetchEpisode(episodeId)
        })
    },
    data() {
        return {
            episode: null,
        }
    },
    async mounted() {},
    methods: {
        async fetchEpisode(episodeId) {
            try {
                var response = await this.$directoryApi.episodes.one(episodeId)
                this.episode = response.data
            } catch (err) {
                console.log(err)
            }
        },
    },
}
</script>

<style scoped>
.top {
    padding: 3rem;
    position: absolute;
    width: 100%;
}

.bottom {
    bottom: 3rem;
    left: 3rem;
    right: 3rem;
    position: absolute;
}

.artwork {
    width: 100%;
    aspect-ratio: 1 / 1;
    margin: auto;
    background-color: darkgray;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
}

.controls {
    width: 100%;
}

.episodeTitle {
    font-size: 1.5rem;
}

.podcastTitle {
    margin-top: 1rem;
    font-size: 1rem;
}

.podcastPublishDate {
    margin-top: 1rem;
    font-size: 0.75rem;
}
</style>

<template>
    <div></div>
</template>

<script setup>
import { inject } from 'vue'
import { useRouter } from 'vue-router'

const auth = inject('auth')
const router = useRouter()

router.replace({ name: 'browse', params: { buildKey: auth.user.profile.site } })
</script>
